import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import Container from './Container';

const Item = ({ title, description }) => {
  return (
    <Col lg={6} md={6} sm={12} xs={12}>
      <div className="reason-item">
        <h2>{title}</h2>
        <div className="reason-divider" />
        <p>{description}</p>
      </div>
    </Col>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

const REASON_DATA = [
  {
    id: 'reason-1',
    title: 'TOP 1',
    description: 'MarTech先行者',
  },
  {
    id: 'reason-2',
    title: '200+',
    description: '企业成功案例最佳实践',
  },
  {
    id: 'reason-3',
    title: '超级增长',
    description: '独创理论体系支持',
  },
  {
    id: 'reason-4',
    title: '40%+',
    description: '营销业绩转化提升40%以上',
  },
];

function WhyBecomePartner() {
  return (
    <section className="why-become-partner">
      <Container>
        <div className="title-wrapper">
          <h3>为什么要成为Convertlab合作伙伴</h3>
        </div>
        <Row className="reason-boxes" gutter={{ sm: 9, xs: 9 }}>
          {REASON_DATA.map((data) => (
            <Item {...data} key={data.id} />
          ))}
        </Row>
        <div className="join-us">
          <a href="https://b.convertlab.com/p/1ed66" target="_blank" rel="noreferrer">
            申请加入
          </a>
        </div>
      </Container>
    </section>
  );
}

export default WhyBecomePartner;
