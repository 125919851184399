import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import AboutUsBanner from '../components/AboutUsBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';
import Container from '../components/Container';
import WhyBecomePartner from '../components/WhyBecomePartner';
import ToBecomePartner from '../components/ToBecomePartner';

const SCEN_DATA = [
  {
    id: 'private-1',
    iconIndex: 64,
    description: 'MarTech声量持续攀升\n市场需求升温',
  },
  {
    id: 'private-2',
    iconIndex: 65,
    description: '业内期待服务标准和\n最佳实践',
  },
  {
    id: 'private-3',
    iconIndex: 66,
    description: '企业需要能解决中国痛\n点的营销技术解决方案',
  },
];

const SUPPORT_DATA = [
  {
    id: 'private-1',
    iconIndex: 67,
    title: '培训赋能',
    description: '专属的工具包、线上线下系列培训、快速提升合作伙伴服务能力',
  },
  {
    id: 'private-2',
    iconIndex: 1,
    title: '市场营销',
    description: '行业论坛、技术沙龙、行业展会、媒体报道全面支持',
  },
  {
    id: 'private-3',
    iconIndex: 68,
    title: '技术支持',
    description: '专业团队支撑合作伙伴联合开发解决方案，及时响应',
  },
];

const WELCOME = () => {
  return (
    <section id="partner-welcome" className="partner-welcome">
      <Container>
        <div className="mask">
          <p>欢迎加入Super Partner合作伙伴计划作为先行者为企业开启创新篇章</p>
        </div>
      </Container>
    </section>
  );
};

export default () => {
  return (
    <Layout current="aboutus" subCurrent="partner">
      <AboutUsBanner title="合作伙伴" description="共创客户价值 驱动超级增长" imgName="partner" />
      <TitledVerticalBoxes
        title="携手Convertlab"
        subTitle="基于一体化营销云，为企业构建“以客户为中心”的数字化转型"
        shadowed
        sectionId="partner-1"
      >
        {SCEN_DATA.map((item) => (
          <Col lg={8} md={12} sm={12} xs={24} className="icon-text-col" key={item.id}>
            <VerticalBox {...item} smLayout="in-row" smWhiteSpace="normal" />
          </Col>
        ))}
      </TitledVerticalBoxes>
      <WELCOME />
      <WhyBecomePartner />
      <ToBecomePartner />
      <TitledVerticalBoxes title="支持与赋能" sectionId="partner-2">
        {SUPPORT_DATA.map((item) => (
          <Col lg={8} md={12} sm={12} xs={24} className="icon-text-col" key={item.id}>
            <VerticalBox {...item} />
          </Col>
        ))}
      </TitledVerticalBoxes>
    </Layout>
  );
};
