import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import PropTypes from 'prop-types';
import Container from './Container';
import TabTitle from './TabTitle';

const ContentItem = ({ title, titleEn, content, bgImg }) => {
  return (
    <Col lg={12} md={12} sm={24} xs={24}>
      <div className={`content-item ${bgImg}`}>
        <p className="title-en">{titleEn}</p>
        <p className="title">{title}</p>
        <p className="content">
          {content.split('\n').map((str) => (
            <span key={str} className="content-str">
              {str}
            </span>
          ))}
        </p>
      </div>
    </Col>
  );
};

ContentItem.propTypes = {
  title: PropTypes.string,
  titleEn: PropTypes.string,
  content: PropTypes.string,
  bgImg: PropTypes.string,
};

const PLAN_DATA = [
  {
    id: 'plan-1',
    title: '菁英伙伴',
    description: '关注MarTech领域合作实现超级增长',
    contentList: [
      {
        id: 'plan-1-1',
        title: '营销类合作伙伴',
        titleEn: 'Agency',
        content: '“品效合一”压力在即，经验思维转向\n数据思维成为必然',
        bgImg: 'join-plan-1',
      },
      {
        id: 'plan-1-2',
        title: '产品类合作伙伴',
        titleEn: 'Bundle/ Integrated Solution',
        content: '从帮客户采集数据，到将数据变成资产，实现更紧密的合作',
        bgImg: 'join-plan-2',
      },
      {
        id: 'plan-1-3',
        title: '渠道类/云服务合作伙伴',
        titleEn: 'Channel/Cloud Service',
        content: '营销云3.0时代来临，一站式一体化服务模式渐成趋势',
        bgImg: 'join-plan-3',
      },
      {
        id: 'plan-1-4',
        title: '咨询类合作伙伴',
        titleEn: 'Digital Transformation Consultant',
        content: '数字化转型成为企业第一需求，帮助客户实现从理论到实践落地及真实增长',
        bgImg: 'join-plan-4',
      },
    ],
  },
  {
    id: 'plan-2',
    title: '认证伙伴',
    description: '取得认证资质合作提升服务能力',
    contentList: [],
  },
  {
    id: 'plan-3',
    title: '开发伙伴',
    description: 'Open Hub开放平台合作共创生态',
    contentList: [],
  },
];

const BREAKPOINT = 991;
function ToBecomePartner() {
  const [currentTabPosition, setTabPosition] = useState('left');

  // 屏宽 < 992 时使用纵列目录
  function onWindowResize() {
    if (window.innerWidth <= BREAKPOINT && currentTabPosition === 'left') {
      setTabPosition('top');
    }
    if (window.innerWidth > BREAKPOINT && currentTabPosition === 'top') {
      setTabPosition('left');
    }
  }

  useEffect(() => {
    setTabPosition(window.innerWidth <= BREAKPOINT ? 'top' : 'left');
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      document.removeEventListener('resize', onWindowResize);
    };
  }, [currentTabPosition]);

  function renderTabBar() {
    return (
      <div className="custom-tab-bar">
        {PLAN_DATA.map((tabItem) => (
          <TabTitle key={tabItem.id} title={tabItem.title} description={tabItem.description} />
        ))}
      </div>
    );
  }

  return (
    <section className="to-become-partner">
      <Container>
        <div className="title-wrapper">
          <h3>加入Convertlab超级合作伙伴计划</h3>
          <p>
            敏捷(Agility),赋能(Activation), 加速(Accelerption), 是Super Partner合作伙伴计划的核心价值点。
          </p>
          <p className="multi-rows">
            诚挚邀请中国的MarTech合作伙伴，在自身的核心能力上，叠加最前沿、可落地的营销与技术手段,以及先进的营销管理咨询理念，与Convertab携手提供企业一 体化营销云解决方案。 通过培训与相关支持，可望拓展专业技能，增加生意机会，进而提升盈利能力。
          </p>
          <p>欢迎成为Convertlab菁英级、认证级、开发级合作伙伴!</p>
        </div>
        <Row className="partner-plans">
          <Tabs tabPosition={currentTabPosition} activeKey="plan-1" tabBarGutter={0} renderTabBar={renderTabBar}>
            {PLAN_DATA.map((tabItem) => (
              <Tabs.TabPane key={tabItem.id}>
                <div className="plan-content-wrapper">
                  <Row gutter={{ lg: 30, md: 30, sm: 0, xs: 0 }}>
                    {tabItem.contentList.map((content) => (
                      <ContentItem key={content.id} {...content} />
                    ))}
                  </Row>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Row>
      </Container>
    </section>
  );
}

export default ToBecomePartner;
